import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import illustration from "../../../assets/images/auth/logo_auth.jpg";
import { RiEyeLine, RiEyeOffFill } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useAddEmployeeForm, useSignUpForm } from "../../utils/forms";
import { boolean } from "yup";
import { getCountries } from "../../services/miscellaneous";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
interface Country {
  country_code: string;
  country_name: string;
}
export default function AddEmployee() {
  // "firstName": "John",
  // "lastName": "Doe",
  // "email": "admin@test.com",
  // "password": "secret",
  // "phone": "+237690909090",
  // "countryCode": "string",
  // "businessId": "string",
  // "areRidesAllowed": true
  const businessInfo = useAppSelector((state: RootState) => state.persist.businessInfo);
  const countryCode = businessInfo.countryCode;
  const phoneCountryCodes = [countryCode === "uk" ? "+44" : "+33"];
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("red", "red");
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useAppDispatch();
  const formik = useAddEmployeeForm(isMobile[0], dispatch);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleClick = () => setShow(!show);
  const [selectOption, setSelectOption] = useState(false);
  const [countryData, setCountryData] = useState<string[]>();
  const handleConfirmClick = () => setShowConfirm(!showConfirm);
  const [cityData, setCityData] = useState<{ city_name: string; value: string }[]>();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    formik.handleSubmit(e as any);
  };
  const countries = [
    countryCode == "uk"
      ? {
          country_code: "uk",
          country_name: "United Kingdom",
        }
      : {
          country_code: "france",
          country_name: "France",
        },
  ];
  const region = [
    {
      city_name: "Ile De France",
      value: "ile-de-france",
      country_code: "france",
    },
    {
      city_name: "London boroughs",
      value: "london-boroughs",
      country_code: "uk",
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const allCountries = await getCountries();
      setCountryData(allCountries);
      console.log(allCountries);
      // if (allCountries) {
      //   const filteredCountries = allCountries.filter(countryCode => countryCode === 'UK' || countryCode === 'FR');
      //   setCountryData(filteredCountries);
      // } else {
      //   console.error("Failed to fetch countries");
      // }
    };
    fetchData();
    setCityData(region);
  }, []);
  useEffect(() => {
    if (formik.values.countryCode === "france") {
      setCityData([{ city_name: "Ile De France", value: "ile-de-france" }]);
    } else if (formik.values.countryCode === "uk") {
      setCityData([{ city_name: "London boroughs", value: "london-boroughs" }]);
    } else {
      setCityData([]);
    }
    console.log(formik.values.countryCode);
  }, [formik.values.countryCode]);
  return (
    <Flex
      maxW="100%"
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="center"
      justifyContent="center"
      mb={{ base: "30px", md: "10px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "5px", md: "0vh" }}
      flexDirection="column"
    >
      <Box me="auto" mx="auto">
        <Text mb="15px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
          Fill up Employee details and add an employee
        </Text>
      </Box>
      <Flex
        alignItems="center"
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              First Name <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Enter employee's first name"
              mb={formSubmitted && formik.errors.firstName ? "0px" : "24px"}
              fontWeight="500"
              size="lg"
              value={formik.values.firstName}
              onChange={formik.handleChange("firstName")}
              onBlur={formik.handleBlur}
            />
            {formSubmitted && formik.errors.firstName && (
              <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                {formik.errors.firstName}
              </Text>
            )}
            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Last Name <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Enter employee's last name"
              mb={formSubmitted && formik.errors.lastName ? "0px" : "24px"}
              fontWeight="500"
              size="lg"
              value={formik.values.lastName}
              onChange={formik.handleChange("lastName")}
              onBlur={formik.handleBlur}
            />
            {formSubmitted && formik.errors.lastName && (
              <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                {formik.errors.lastName}
              </Text>
            )}
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@gmail.com"
              mb={formSubmitted && formik.errors.email ? "0px" : "24px"}
              fontWeight="500"
              size="lg"
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur}
            />
            {formSubmitted && formik.errors.email && (
              <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                {formik.errors.email}
              </Text>
            )}
            {/* <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb={
                  formSubmitted && formik.errors.password ? "0px" : "24px"
                }
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={formik.values.password}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeLine : RiEyeOffFill}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {formSubmitted && formik.errors.password && (
              <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                {formik.errors.password}
              </Text>
            )} */}
            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Phone Number <Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="lg">
              <InputLeftAddon
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                mb={formSubmitted && formik.errors.phone ? "24px" : "24px"}
                fontWeight="500"
              >
                <Select
                  fontSize="sm"
                  variant="auth"
                  defaultValue={"+91"}
                  fontWeight="500"
                  size="lg"
                  border="none"
                  value={formik.values.phoneCountryCode}
                  onChange={(e) => formik.setFieldValue("phoneCountryCode", e.target.value)}
                >
                  {phoneCountryCodes.map((code) => (
                    <option
                      key={code}
                      value={code}
                      disabled={formik.values.phoneCountryCode === code}
                    >
                      {code}
                    </option>
                  ))}
                </Select>
              </InputLeftAddon>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="number"
                placeholder="9876543210"
                mb={formSubmitted && formik.errors.phone ? "0px" : "24px"}
                fontWeight="500"
                size="lg"
                value={formik.values.phone}
                onChange={formik.handleChange("phone")}
                onBlur={formik.handleBlur}
              />
            </InputGroup>
            {formSubmitted && formik.errors.phone && (
              <Text
                marginBottom={"24px"}
                marginTop={"-24px"}
                marginLeft={"10px"}
                fontSize={14}
                color="red"
              >
                {formik.errors.phone}
              </Text>
            )}
            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Country <Text color={brandStars}>*</Text>
            </FormLabel>
            <Select
              fontSize="sm"
              variant="auth"
              defaultValue={""}
              fontWeight="500"
              size="lg"
              borderWidth="1px"
              borderColor="gray.300"
              borderRadius="15"
              mb={formSubmitted && formik.errors.countryCode ? "0px" : "24px"}
              value={formik.values.countryCode}
              onChange={(e) => formik.setFieldValue("countryCode", e.target.value)}
            >
              {countries?.map((country, index) => (
                <option key={index} value={country.country_code}>
                  {country.country_name}
                </option>
              ))}
            </Select>
            {/* <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Are Rides Allowed <Text color={brandStars}>*</Text>
            </FormLabel>
            <RadioGroup
              name="areRidesAllowed"
              value={formik.values.areRidesAllowed.toString()}
              onChange={(value) => {
                const rideState = value === 'true';
                formik.setFieldValue('areRidesAllowed', rideState);
              }}
            >
              <Stack direction="row"
                mb={"24px"}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
            {formSubmitted && formik.errors.areRidesAllowed && (
              <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                {formik.errors.areRidesAllowed}
              </Text>
            )} */}
            <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb={formSubmitted && formik.errors.password ? "0px" : "24px"}
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={formik.values.password}
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeLine : RiEyeOffFill}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {formSubmitted && formik.errors.password && (
              <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                {formik.errors.password}
              </Text>
            )}
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
              mt="10px"
            >
              Add Employee
            </Button>
          </FormControl>
        </form>
      </Flex>
    </Flex>
  );
}
