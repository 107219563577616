import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Switch,
  Button,
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  Select,
  Box,
  Heading,
} from "@chakra-ui/react";
import { CheckIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import { getAllEmployees } from "../../services/Employee";
import {
  activateEmployee,
  deleteEmployee,
  getSubscriptionList,
  updateUserRideStatus,
} from "../../services/user";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import AddEmployee from "./AddEmployee";
import axios from "axios";
import { baseURL, paymentServiceBaseUrl } from "../../globals/constants";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { fetchSubscriptionDataFailure } from "../../slices/subsriptionSlice";

interface Employee {
  _id: string;
  countryCode: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  state: string;
  status: string;
  areRidesAllowed: boolean;
  paymentMethodId: string;
}

const EmployeeTable: React.FC = () => {
  const { list: subscriptionList, paymentDetails } = useAppSelector(
    (state: RootState) => state.persist.subs
  );
  const [employeesData, setEmployeesData] = useState<Employee[]>([]);
  const [userCards, setUserCards] = useState<any>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 5;
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isGlobalRidesAllowed, setIsGlobalRidesAllowed] = useState(false);
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const { countryCode } = useAppSelector((state: RootState) => state.persist.businessInfo);
  const [employeeUpdated, setEmployeeUpdated] = useState(false);
  const subscriptionPlanIds = subscriptionList.reduce((acc: any, product: any) => {
    acc[product.name.toLowerCase()] = product.id;
    return acc;
  }, {});

  // const subscriptionPlanIds =
  //   countryCode === "uk"
  //     ? {
  //         basic: "prod_QflemIfgjkWIO9",
  //         premium: "prod_QflgyLABNECH4x",
  //         platinum: "prod_QflhpunXoGbFJ3",
  //       }
  //     : {
  //         basic: "prod_PqhKi25EZ3oa3B",
  //         premium: "prod_PqhO8zC7RQuoSu",
  //         platinum: "prod_PqhWfJi9mEZOwG",
  //       };

  const fetchUserCards = async () => {
    setIsLoading(true);
    const response = await axios.post(
      `${paymentServiceBaseUrl}/customer/payment_method/list`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      setUserCards(response.data.data);
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await getSubscriptionList(dispatch);
  //   };

  //   fetchData();
  // }, [dispatch]);

  useEffect(() => {
    fetchUserCards();
    isRidesAllowedForAllEmployees();
  }, []);

  const handleGlobalRidesAllowedToggles = async (status: any) => {
    setIsLoading(true);
    try {
      const decodedToken: any = jwtDecode(token ? token : "");
      const businessId = decodedToken._id;
      const response = await axios.patch(
        `${baseURL}/business/${businessId}/update-rides-allowed-all-employees`,
        { areRidesAllowed: status },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        showSuccessToast(status ? "Rides allowed for all " : "Rides disallowed for all ", true);
        setIsGlobalRidesAllowed(status);
        await fetchEmployees();
      }
    } catch (error) {
      console.error("Error updating rides :", error);
      showErrorToast("Error updating rides", true);
    } finally {
      setIsLoading(false);
    }
  };

  const addEmployeeClick = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    fetchEmployees();
    setEmployeeUpdated(false);
  }, [isGlobalRidesAllowed, employeeUpdated]);

  const fetchEmployees = async () => {
    try {
      const response = await getAllEmployees();
      if (response.data) {
        setEmployeesData(response.data);
      } else {
        console.error("Failed to fetch employees:", response);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const isRidesAllowedForAllEmployees = async () => {
    try {
      const decodedToken: any = jwtDecode(token ? token : "");
      const businessId = decodedToken._id;
      const response = await axios.get(`${baseURL}/business/${businessId}/are-rides-allowed`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsGlobalRidesAllowed(response.data.areRidesAllowed);
    } catch (error) {
      console.error("Error fetching Is rides allowed for all employees", error);
      return false;
    }
  };

  const handleRidesAllowedToggle = async (
    employeeId: string,
    newValue: boolean,
    employeeDetails: any
  ) => {
    if (employeeDetails.ridesAllowed !== newValue) {
      const response = await updateUserRideStatus(
        employeeId,
        { areRidesAllowed: newValue },
        employeeDetails
      );
      if (response) {
        try {
          const result = await getAllEmployees();
          if (result && result.data) {
            setEmployeesData(result.data);
          }
          await isRidesAllowedForAllEmployees();
        } catch (error) {
          console.error("Error fetching employees:", error);
        }
      }
    }
  };

  // const handlePreviousPage = () => {
  //   setPageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  // };

  // const handleNextPage = () => {
  //   setPageIndex((prevIndex) =>
  //     Math.min(prevIndex + 1, Math.ceil(employeesData.length / pageSize) - 1)
  //   );
  // };
  const paginatedData = [...employeesData]
    .sort((a, b) => (a.status === "active" ? 1 : -1))
    .reverse()
    .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  const handleCardSelection = async (event: any, employeeId: string) => {
    const token = localStorage.getItem("access_token");
    const decodedToken: any = jwtDecode(token ? token : "");

    const response = await axios.put(
      `${baseURL}/users/${employeeId}`,
      { paymentMethodId: event.target.value, customerId: decodedToken.customerId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response) {
      showSuccessToast("Payment Method Updated", true);
    }
  };

  const showEmployeeRides = (employeeId: string) => {
    navigate(`/employee-rides/${employeeId}`);
  };

  const delEmployee = async (employeeId: string) => {
    try {
      const delResponse = await deleteEmployee(employeeId);
      if (delResponse.status === 200) {
        setEmployeeUpdated(true);
        showSuccessToast("Employee deleted successfully", false);
      } else {
        setEmployeeUpdated(false);
        showErrorToast(delResponse.data.message, false);
      }
    } catch (error: any) {
      showErrorToast(error.message, false);
    }
  };

  const activEmployee = async (employeeId: string) => {
    try {
      const activateResponse = await activateEmployee(employeeId);
      if (activateResponse.status === 200) {
        setEmployeeUpdated(true);
        showSuccessToast("Employee activated successfully", false);
      } else {
        setEmployeeUpdated(false);
        showErrorToast(activateResponse.data.message, false);
      }
    } catch (error: any) {
      showErrorToast(error.message, false);
    }
  };
  const subscribeClick = () => {
    navigate("/subscription");
  };
  const disableAddEmployee =
    (subscriptionPlanIds.basic === paymentDetails?.planId && employeesData.length >= 2) ||
    (subscriptionPlanIds.premium === paymentDetails?.planId && employeesData.length >= 10);
  console.log("disableAddEmployee", disableAddEmployee);

  return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      align="start"
      justify="start"
      direction="column"
      height="100vh"
    >
      <Flex alignSelf="end" mb="20px">
        {paymentDetails &&
          paymentDetails.paymentStatus === "paid" &&
          subscriptionPlanIds.basic !== paymentDetails?.planId && (
            <>
              <Text
                mr="4"
                mt="2"
                cursor="pointer"
                fontSize="lg"
                fontWeight="bold"
                color={isGlobalRidesAllowed ? "blue.500" : "red.500"}
              >
                {isGlobalRidesAllowed ? "Rides Allowed" : "Rides Disallowed"}
              </Text>
              <Switch
                isChecked={isGlobalRidesAllowed}
                onChange={() => handleGlobalRidesAllowedToggles(!isGlobalRidesAllowed)}
                isDisabled={isLoading}
                mr="4"
                mt="2"
              />
            </>
          )}
        {paymentDetails && paymentDetails.paymentStatus === "paid" && paymentDetails?.planId && (
          <Button
            fontSize={{ base: "xs", md: "sm" }}
            w={{ base: "auto" }}
            h={{ base: "40px", md: "40px" }}
            variant="brand"
            fontWeight="500"
            type="button"
            onClick={addEmployeeClick}
            isDisabled={disableAddEmployee}
          >
            Add Employee
          </Button>
        )}
      </Flex>

      {paymentDetails && paymentDetails.paymentStatus === "paid" ? (
        <>
          <Modal isOpen={isOpen} onClose={handleClose} size="xl">
            <ModalOverlay />
            <ModalContent width="90%" maxW="600px" maxHeight="80vh">
              <ModalHeader>Add Employee</ModalHeader>
              <ModalCloseButton />
              <ModalBody overflowY="auto">
                <AddEmployee />
              </ModalBody>
            </ModalContent>
          </Modal>
          <Table variant="striped" colorScheme="gray" minWidth="100%">
            <Thead>
              <Tr style={{ backgroundColor: "#422AFB" }}>
                <Th style={{ color: "#FFFFFF" }}>Employee Name</Th>
                <Th style={{ color: "#FFFFFF" }}>Phone</Th>
                <Th style={{ color: "#FFFFFF" }}>State</Th>
                <Th style={{ color: "#FFFFFF" }}>Country</Th>
                <Th style={{ color: "#FFFFFF" }}>Modify Payment Method</Th>
                <Th style={{ color: "#FFFFFF" }}>Action</Th>
                {subscriptionPlanIds.basic !== paymentDetails?.planId && (
                  <>
                    <Th style={{ color: "#FFFFFF" }}>Rides Allowed</Th>
                    <Th style={{ color: "#FFFFFF" }}>View Rides</Th>
                  </>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((employee) => (
                  <Tr key={employee._id}>
                    <Td>
                      {employee.firstName} {employee.lastName}
                    </Td>
                    <Td>{employee.phone}</Td>
                    <Td style={{ textTransform: "uppercase" }}>{employee.status}</Td>
                    <Td style={{ textTransform: "capitalize" }}>{employee.countryCode}</Td>
                    <Td>
                      <Select
                        size="sm"
                        onChange={(event) => handleCardSelection(event, employee._id)}
                      >
                        {/* Add the disabled option as the first item */}
                        <option value="" disabled selected={!employee.paymentMethodId}>
                          Choose Card
                        </option>
                        {userCards?.map((card: any, index: number) => (
                          <option
                            key={index}
                            value={card.id}
                            selected={card.id === employee.paymentMethodId}
                          >
                            **** **** **** {card.card.last4}
                          </option>
                        ))}
                      </Select>
                    </Td>
                    <Td>
                      {employee.status === "active" && (
                        <DeleteIcon onClick={() => delEmployee(employee._id)} cursor="pointer" />
                      )}
                      {employee.status === "deleted" && (
                        <CheckIcon onClick={() => activEmployee(employee._id)} cursor="pointer" />
                      )}
                    </Td>
                    {subscriptionPlanIds.basic !== paymentDetails?.planId && (
                      <>
                        <Td>
                          <Switch
                            isChecked={employee ? employee.areRidesAllowed : false}
                            onChange={(e) =>
                              handleRidesAllowedToggle(employee._id, e.target.checked, {
                                firstName: employee.firstName,
                                lastName: employee.lastName,
                                ridesAllowed: employee?.areRidesAllowed,
                              })
                            }
                          />
                        </Td>
                        <Td>
                          <ViewIcon
                            onClick={() => showEmployeeRides(employee._id)}
                            cursor="pointer"
                          />
                        </Td>
                      </>
                    )}
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={7} style={{ textAlign: "center" }}>
                    No employees found
                  </Td>
                </Tr>
              )}
            </Tbody>
            <tfoot>
              <Tr>
                <Td colSpan={7} style={{ textAlign: "center" }}>
                  <Button onClick={() => setPageIndex(pageIndex - 1)} isDisabled={pageIndex === 0}>
                    Previous Page
                  </Button>
                  <span> </span>
                  <Button
                    onClick={() => setPageIndex(pageIndex + 1)}
                    isDisabled={
                      paginatedData.length < pageSize ||
                      (pageIndex + 1) * pageSize >= employeesData.length
                    }
                  >
                    Next Page
                  </Button>
                </Td>
              </Tr>
            </tfoot>
          </Table>
        </>
      ) : (
        <>
          <Flex
            pt={{ base: "130px", md: "80px", xl: "80px" }}
            align="center"
            justify="start"
            direction="column"
            height="100vh"
            width="100%"
          >
            <Text>
              You have not subscribed to any plan yet, click on the below button to subscribe a plan
              !
            </Text>
            <Button
              fontSize={{ base: "xs", md: "sm" }}
              w={{ base: "auto" }}
              h={{ base: "40px", md: "40px" }}
              mt="1%"
              variant="brand"
              fontWeight="500"
              letterSpacing={1}
              type="button"
              onClick={subscribeClick}
            >
              Subscribe
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default EmployeeTable;
