import axios from "axios";
import { Dispatch } from "redux";
import {
  fetchAuthDataFailure,
  fetchAuthDataStart,
  fetchAuthUser,
  fetchLoginToken,
} from "../slices/authSlice";
import {
  fetchSubscriptionDataFailure,
  fetchSubscriptionDataStart,
  fetchSubscriptionDetails,
  fetchSubscriptionList,
  fetchUpdateCards,
} from "../slices/subsriptionSlice";
import { baseURL, paymentServiceBaseUrl } from "../globals/constants";
import Cookies from "js-cookie";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { log } from "console";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
  setAddress,
  setAreRidesAllowed,
  setCountryCode,
  setEmail,
  setName,
  setNatureOfBusiness,
  setPaymentMethods,
  setPhone,
  setRegionCode,
  setRegistrationNumber,
} from "../slices/BusinessSlice";

interface LoginFormValues {
  email: string;
  password: string;
}
interface validateOTPFormValues {
  otp: string;
}
interface RegisterFormValues {
  name: string;
  email: string;
  password: string;
  phone: string;
}

export interface CreateStripeCustomer {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
}
interface AddEmployee {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  countryCode: string;
  areRidesAllowed: boolean;
}
interface ProfileValues {
  name: string;
  email: string;
  phone: string;
  address: string;
  registrationNumber: string;
  natureOfBusiness: string;
  countryCode: string;
  regionCode: string;
  // areRidesAllowed: boolean;
}
interface ProfileValuess {
  userid: string;
}
interface SubscriptionCreationValues {
  userId: string;
  name: string;
  priceId: string;
  email: string;
  planName: string;
}

export const loginUser = async (values: LoginFormValues, dispatch: Dispatch) => {
  try {
    dispatch(fetchAuthDataStart());
    const response: any = await axios.post(
      `${baseURL}/business/login`,
      {
        email: values.email,
        password: values.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch(fetchAuthUser(response.data?.user));
    dispatch(fetchLoginToken(response.data?.token));
    // dispatch(fetchAuthUser(response.data?.token));
    // console.log(response)
    // localStorage.setItem("token",response.data.token)

    // localStorage.setItem('acess_token', response?.data.token);
    localStorage.setItem("user", JSON.stringify(response.data));
    dispatch(fetchAuthDataFailure());
    return response;
  } catch (error: any) {
    dispatch(fetchAuthDataFailure());
    throw error;
  }
};

export const ValidateOTP = async (values: validateOTPFormValues, dispatch: Dispatch) => {
  // const token=useSelector((state:RootState)=>state.persist.auth.token)
  const token = localStorage.getItem("login_token");
  let code: number = parseInt(values.otp);
  try {
    const response: any = await axios.post(`${baseURL}/business/verify-login-otp`, {
      code: code,
      token: token,
    });
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const addEmployee = async (values: AddEmployee) => {
  const to = localStorage.getItem("access_token") || "";
  const decoded: { _id?: string } = jwtDecode(to);
  const businessId = decoded._id || "";
  let data = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    phone: values.phone.toString(),
    countryCode: values.countryCode,
    businessId: businessId,
    areRidesAllowed: values.areRidesAllowed,
  };
  let token = localStorage.getItem("access_token");
  try {
    console.log(JSON.stringify(values));
    const response = await axios.post(`${baseURL}/business/add-employee`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteEmployee = async (employeeId: string) => {
  const to = localStorage.getItem("access_token") || "";
  const decoded: { _id?: string } = jwtDecode(to);
  const businessId = decoded._id || "";

  let token = localStorage.getItem("access_token");
  try {
    const response = await axios.delete(
      `${baseURL}/business/${businessId}/employee/${employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const activateEmployee = async (employeeId: string) => {
  const to = localStorage.getItem("access_token") || "";
  const decoded: { _id?: string } = jwtDecode(to);
  const businessId = decoded._id || "";

  let token = localStorage.getItem("access_token");
  try {
    const response = await axios.patch(
      `${baseURL}/business/${businessId}/employee/${employeeId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const registerUser = async (values: RegisterFormValues) => {
  console.log("in user " + JSON.stringify(values));
  try {
    const response = await axios.post(`${baseURL}/business/register`, values, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const createCustomerId = async (
  values: CreateStripeCustomer,
  token: string,
  businessId: string
) => {
  try {
    const response = await axios.post(`${paymentServiceBaseUrl}/customer/create/new`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let updateCustomerIdPayload = {
      customerId: response.data.customerId,
    };
    await axios.put(`${baseURL}/business/${businessId}/update`, updateCustomerIdPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error: any) {
    throw error;
  }
};

export const updateProfile = async (values: ProfileValues, dispatch: Dispatch) => {
  const token = localStorage.getItem("access_token") || "";
  const decoded: { _id?: string } = jwtDecode(token);
  console.log("decoded", decoded);
  const businessId = decoded._id || "";
  try {
    if (token) {
      console.log("inside profile");
      const response = await axios.put(`${baseURL}/business/${businessId}/update`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data.data);
      localStorage.setItem("access_token", response.data.data.accessToken);
      // Cookies.set("access_token", response.data.data.accessToken);
      return response.data.data.updatedBusiness;
    }
  } catch (error: any) {
    dispatch(fetchAuthDataFailure());
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};

export const getProfile = async (id: string, dispatch: Dispatch) => {
  const token: string = localStorage.getItem("access_token") || "";
  try {
    dispatch(fetchAuthDataStart());
    const response = await axios.get(`${baseURL}/users/user/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(fetchAuthUser(response.data));
    const { firstName, lastName, phone, email } = response.data;
    const userData = JSON.stringify({
      firstName,
      lastName,
      email,
      userId: response?.data?.id,
      phone,
    });
    localStorage.setItem("user", userData);
    dispatch(fetchAuthDataFailure());
    return response.data;
  } catch (error: any) {
    dispatch(fetchAuthDataFailure());
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};

export const logout = async () => {
  const token: string = localStorage.getItem("access_token") || "";
  try {
    const response = await axios.post(`${baseURL}/auth/logout`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      window.location.replace("/");
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("isLogged");
      // Cookies.remove("access_token");
      return response;
    }
  } catch (error: any) {
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      localStorage.removeItem("isLogged");
      // Cookies.remove("access_token");
    }
  }
};

export const getSubscriptionList = async (dispatch: Dispatch) => {
  const token: string | null =
    Cookies.get("access_token") || localStorage.getItem("access_token") || "";
  try {
    const decodedToken: any = jwtDecode(token);
    console.log("decoded token inside get subscription list", decodedToken);
    dispatch(fetchSubscriptionDataStart());
    const response = await axios.get(
      `${baseURL}/subscription/plan/list/${decodedToken.countryCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("response subscription list --- ", response);
    dispatch(fetchSubscriptionList(response.data));
    // localStorage.setItem("subscribe", JSON.stringify(response.data));
  } catch (error: any) {
    dispatch(fetchSubscriptionDataFailure());
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};

export const createSubscription = async (values: SubscriptionCreationValues) => {
  const token: string | null =
    Cookies.get("access_token") || localStorage.getItem("access_token") || "";
  try {
    const response = await axios.post(`${baseURL}/subscription/create`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};

export const checkSubscription = async (dispatch: Dispatch) => {
  const token: string | null =
    Cookies.get("access_token") || localStorage.getItem("access_token") || "";
  const decodedToken: any = jwtDecode(token);
  try {
    dispatch(fetchSubscriptionDataStart());
    const response = await axios.get(
      `${baseURL}/subscription/payment/status/${decodedToken._id}/${decodedToken.countryCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Payment status", response.data);
    dispatch(fetchSubscriptionDetails(response.data));
    // localStorage.setItem("paymentStatus", JSON.stringify(response.data));
  } catch (error: any) {
    dispatch(fetchSubscriptionDataFailure());
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};

export const manageAccounts = async (dispatch: Dispatch) => {
  const token: string | null =
    Cookies.get("access_token") || localStorage.getItem("access_token") || "";
  const decodedToken: any = jwtDecode(token);
  try {
    dispatch(fetchSubscriptionDataStart());
    const response = await axios.get(
      `${baseURL}/subscription/customer-portal/${decodedToken._id}/${decodedToken.countryCode}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(fetchUpdateCards(response.data?.customerPortalUrl));
    // localStorage.setItem("manage_account", JSON.stringify(response.data));
  } catch (error: any) {
    dispatch(fetchSubscriptionDataFailure());
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};

export const updateUserRideStatus = async (userId: any, values: any, name?: any) => {
  const token = localStorage.getItem("access_token") || "";
  try {
    if (token) {
      const response = await axios.put(`${baseURL}/users/${userId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && name && Object.keys(name).length !== 0) {
        showSuccessToast(
          values.areRidesAllowed
            ? `Rides allowed for ${name.firstName} ${name.lastName}`
            : `Rides disallowed for ${name.firstName} ${name.lastName}`,
          true
        );
      }
      return response.data;
    }
  } catch (error: any) {
    console.log(error, "error update user");
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};

export const updateBusinessRideStatus = async (dispatch: Dispatch, values: any) => {
  const token = localStorage.getItem("access_token") || "";
  const decoded: { _id?: string } = jwtDecode(token);
  const businessId = decoded._id || "";
  try {
    if (businessId) {
      console.log("inside profile");
      const response = await axios.put(`${baseURL}/business/${businessId}/update`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response.data.data);
      localStorage.setItem("access_token", response.data.data.accessToken);
      // Cookies.set("access_token", response.data.data.accessToken);
      dispatch(setName(response.data.data.updatedBusiness.name));
      dispatch(setEmail(response.data.data.updatedBusiness.email));
      dispatch(setPhone(response.data.data.updatedBusiness.phone));
      dispatch(setAddress(response.data.data.updatedBusiness.address));
      dispatch(setRegionCode(response.data.data.updatedBusiness.regionCode));
      dispatch(setRegistrationNumber(response.data.data.updatedBusiness.registrationNumber));
      dispatch(setCountryCode(response.data.data.updatedBusiness.countryCode));
      dispatch(setNatureOfBusiness(response.data.data.updatedBusiness.natureOfBusiness));
      dispatch(setAreRidesAllowed(response.data.data.updatedBusiness.areRidesAllowed));
      dispatch(setPaymentMethods(response.data.data.updatedBusiness.paymentMethods));
      return response.data.data.updatedBusiness;
    }
  } catch (error: any) {
    console.log(error, "error update user");
    if (error?.response?.data.message.includes("Token expired")) {
      showErrorToast("Session Expired", true);
      await logout();
    }
  }
};
