import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addEmployee,
  createCustomerId,
  CreateStripeCustomer,
  loginUser,
  logout,
  registerUser,
  updateProfile,
  ValidateOTP,
} from "../services/user";
import { showErrorToast, showSuccessToast } from "./toast";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { Dispatch } from "redux";
import { fetchAuthDataReset, fetchLoginToken } from "../slices/authSlice";
import { fetchInfraDataReset } from "../slices/infraSlice";
import { fetchSubscriptionDataReset } from "../slices/subsriptionSlice";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import { log } from "console";
import { useSelector } from "react-redux";
import {
  setAddress,
  setAreRidesAllowed,
  setCountryCode,
  setEmail,
  setName,
  setNatureOfBusiness,
  setPaymentMethods,
  setPhone,
  setRegionCode,
  setRegistrationNumber,
} from "../slices/BusinessSlice";
import { Alert } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";

interface LoginFormValues {
  email: string;
  password: string;
}
interface validateOTPFormValues {
  otp: string;
}

interface RegisterFormValues {
  name: string;
  natureOfBusiness: string;
  registrationNumber: string;
  countryCode: string;
  email: string;
  password: string;
  phone: string;
  confirmPassword: string;
  phoneCountryCode: string;
  regionCode: string;
  address: string;
}
interface AddEmployee {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  countryCode: string;
  areRidesAllowed: boolean;
  phoneCountryCode: string;
}

interface ProfileValues {
  name: string;
  email: string;
  phone: string;
  address: string;
  registrationNumber: string;
  natureOfBusiness: string;
  countryCode: string;
  regionCode: string;
  areRidesAllowed: boolean;
  paymentMethods: string[];
}

const signInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

const signUpSchema = Yup.object().shape({
  name: Yup.string().required("First name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string()
    .matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*/, {
      message:
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character",
    })
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
  phoneCountryCode: Yup.string().required("Country is required"),
  natureOfBusiness: Yup.string().required("Nature of Business is required"),
  registrationNumber: Yup.string().required("Registration Number is required"),
  countryCode: Yup.string().required("Country is required"),
  regionCode: Yup.string().required("Region is required"),
});
const validateOTPSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});

const profileValidationSchema = Yup.object().shape({
  phone: Yup.string().required("Phone number is required"),
  name: Yup.string().required("Company Name is required"),
});
const AddEmployeeValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
  phone: Yup.string().required("Phone Number is required"),
  countryCode: Yup.string().required("Country Code is required"),
});

export const useSignInForm = (isMobile: boolean, dispatch: Dispatch) => {
  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      try {
        const response: any = await loginUser(values, dispatch);
        if (response.status >= 200 && response.status <= 301) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          // const accessToken = Cookies.get("access_token");
          // accessToken && localStorage.setItem("access_token", accessToken);
          localStorage.setItem("login_token", response.data.token);
          showSuccessToast(response.message, isMobile);
          // console.log(response.data.token);
          window.location.replace("/verify-login-otp");
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message || "An error occurred during login";
        showErrorToast(errorMessage, isMobile);
      }
    },
  });
  return formik;
};
export const useValidateOTPForm = (isMobile: boolean, dispatch: Dispatch) => {
  const navigate = useNavigate();
  const formik = useFormik<validateOTPFormValues>({
    initialValues: {
      otp: "",
    },
    validationSchema: validateOTPSchema,
    onSubmit: async (values) => {
      try {
        const response: any = await ValidateOTP(values, dispatch);
        if (response.status >= 200 && response.status <= 301) {
          console.log(response);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.removeItem("login_token");
          localStorage.setItem("access_token", response.data.accessToken);
          const decoded = jwtDecode(response.data.accessToken) as any;
          dispatch(setAddress(decoded?.address));
          dispatch(setName(decoded?.name));
          dispatch(setEmail(decoded?.email));
          dispatch(setPhone(decoded?.phone));
          dispatch(setCountryCode(decoded?.countryCode));
          dispatch(setNatureOfBusiness(decoded?.natureOfBusiness));
          dispatch(setRegistrationNumber(decoded?.registrationNumber));
          dispatch(setRegionCode(decoded?.regionCode));
          dispatch(setAreRidesAllowed(decoded?.areRidesAllowed));
          window.location.replace("/employee");
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message || "An error occurred during login";
        showErrorToast(errorMessage, isMobile);
      }
    },
  });
  return formik;
};
export const useAddEmployeeForm = (isMobile: boolean, dispatch: Dispatch) => {
  const navigate = useNavigate();
  const businessInfo = useAppSelector((state: RootState) => state.persist.businessInfo);
  const countryCode = businessInfo.countryCode;
  const formik = useFormik<AddEmployee>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      countryCode: countryCode,
      areRidesAllowed: false,
      phoneCountryCode: countryCode === "uk" ? "+44" : "+33",
    },
    validationSchema: AddEmployeeValidationSchema,
    onSubmit: async (values) => {
      console.log("phoneCountryCode", values.phoneCountryCode);
      try {
        const phoneCountryCode = values.phoneCountryCode;
        const fullPhoneNumber = `${phoneCountryCode}${values.phone}`;
        const adjustedValues = {
          ...values,
          phone: fullPhoneNumber,
        };
        // delete values.phoneCountryCode;
        const response: any = await addEmployee(adjustedValues);
        if (response.status >= 200 && response.status <= 301) {
          console.log(response);
          const successMessage = response?.data?.message || "User added successfully .";
          showSuccessToast(successMessage, isMobile);
          window.location.replace("/employee");
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message || "An error occurred during login";
        showErrorToast(errorMessage, isMobile);
      }
    },
  });
  return formik;
};
const isNumeric = (input: string): boolean => {
  const regex = /^\d+$/;
  return regex.test(input);
};

export const useSignUpForm = (isMobile: boolean, dispatch: Dispatch) => {
  const navigate = useNavigate();
  const formik = useFormik<RegisterFormValues>({
    initialValues: {
      name: "",
      email: "",
      natureOfBusiness: "",
      password: "",
      phone: "",
      confirmPassword: "",
      phoneCountryCode: "+44",
      countryCode: "uk",
      registrationNumber: "",
      regionCode: "london-boroughs",
      address: "",
    },
    validationSchema: signUpSchema,

    onSubmit: async (values) => {
      const regString = values.registrationNumber.toString();
      if (values.countryCode === "france") {
        values.regionCode = "ile-de-france";
        if (
          (values.registrationNumber && !isNumeric(values.registrationNumber)) ||
          regString.length !== 14
        ) {
          showErrorToast("Registration number format is invalid", isMobile);
          return;
        }
      } else if (values.countryCode === "uk") {
        values.regionCode = "london-boroughs";
        if (
          (values.registrationNumber && !isNumeric(values.registrationNumber)) ||
          regString.length != 8
        ) {
          showErrorToast("Registration number format is invalid", isMobile);
          return;
        }
      }
      values.address = "abc";

      try {
        const { phoneCountryCode, confirmPassword, ...valuesToSubmit } = values;
        const fullPhoneNumber = `${phoneCountryCode}${valuesToSubmit.phone}`;
        const adjustedValues = {
          ...valuesToSubmit,
          phone: fullPhoneNumber,
        };

        // adjustedValues.address="";
        const response: any = await registerUser(adjustedValues);
        // console.log("signup response business", response.data.data.business);
        // console.log("email verification token", response.data.data.emailVerificationToken);
        if (response.status >= 200 && response.status <= 301) {
          navigate("/");
          const successMessage =
            response?.data?.message || "Account created successfully . Please Login ";
          showSuccessToast(successMessage, isMobile);
          let business = response.data?.data?.business;
          let businessId = response.data?.data?.business._id;
          let respToken = response.data.data.emailVerificationToken;
          let createStripeCustomerValues: CreateStripeCustomer = {
            firstName: business.name,
            lastName: "",
            email: business.email,
            phone: business.phone,
            countryCode: business.countryCode,
          };
          await createCustomerId(createStripeCustomerValues, respToken, businessId);
        }
      } catch (error: any) {
        let errorMessage = "An error occurred during Register";

        if (error.response) {
          if (error.response.status === 400 && error.response.data && error.response.data.message) {
            if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message[0];
            } else {
              errorMessage = error.response.data.message;
            }
          } else if (
            error.response.status === 409 &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }
        }

        showErrorToast(errorMessage, isMobile);
      }
    },
  });
  return formik;
};

export const useProfileForm = (isMobile: boolean, dispatch: Dispatch) => {
  const navigate = useNavigate();
  const businessInfo = useAppSelector((state: RootState) => state.persist.businessInfo);
  console.log("businessInfo", businessInfo);

  let phoneCountryCode = "";
  let phone = "";
  if (businessInfo) {
    const phoneNumber = businessInfo.phone;
    if (phone && phone.length >= 10) {
      phoneCountryCode = phone.substring(0, phone.length - 10);
      phone = phoneNumber.substring(phone.length - 10);
    }
  }
  const formik = useFormik<ProfileValues>({
    initialValues: {
      name: businessInfo.name,
      email: businessInfo.email,
      phone: businessInfo.phone,
      address: businessInfo.address,
      registrationNumber: businessInfo.registrationNumber,
      natureOfBusiness: businessInfo.natureOfBusiness,
      countryCode: businessInfo.countryCode,
      regionCode: businessInfo.regionCode,
      areRidesAllowed: businessInfo.areRidesAllowed,
      paymentMethods: businessInfo.paymentMethods,
    },
    validationSchema: profileValidationSchema,
    onSubmit: async (values) => {
      try {
        const response: any = await updateProfile(values, dispatch);
        console.log("response", response);
        const success = "Updated Successfully";
        dispatch(setName(response.data.name));
        dispatch(setEmail(response.data.email));
        dispatch(setPhone(response.data.phone));
        dispatch(setAddress(response.data.address));
        dispatch(setRegionCode(response.data.regionCode));
        dispatch(setRegistrationNumber(response.data.registrationNumber));
        dispatch(setCountryCode(response.data.countryCode));
        dispatch(setNatureOfBusiness(response.data.natureOfBusiness));
        dispatch(setAreRidesAllowed(response.data.areRidesAllowed));
        dispatch(setPaymentMethods(response.data.paymentMethods));
        console.log("forms " + businessInfo.name);
        showSuccessToast(success, isMobile);
        navigate("/profile");
      } catch (error: any) {
        const errorMessage = "An error occurred during profile updation";
        showErrorToast(errorMessage, isMobile);
      }
    },
  });
  return formik;
};

export const userLogout = async (isMobile: boolean, dispatch: Dispatch) => {
  try {
    await logout();
    dispatch(fetchAuthDataReset());
    dispatch(fetchInfraDataReset());
    dispatch(fetchSubscriptionDataReset());
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("isLogged");
    Cookies.remove("access_token");
    showSuccessToast("Logout Successful ", isMobile);
    window.location.replace("/");
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message[0] || "An error occurred during logging out";
    showErrorToast(errorMessage, isMobile);
  }
};
